<template>
  <div>
    <v-col v-if="getAuthMessage.message" cols="12">
      <vue-inline-alert
        :message="getAuthMessage.message"
        :message-type="getAuthMessage.type"
      ></vue-inline-alert>
    </v-col>
    <v-form class="mt-15" @submit.stop.prevent="onSubmit">
      <v-text-field
        label="Password"
        placeholder="Enter the new password"
        filled
        v-model="form.password"
        v-validate="'required|max:30|min:3'"
        counter="30"
        v-bind="veeValidate('Password1', 'Password1')"
        type="password"
      />
      <v-text-field
        label="Confirm password"
        placeholder="Confirm your password"
        filled
        v-model="form.confirm_password"
        v-validate="'required|max:30|min:3'"
        counter="30"
        v-bind="veeValidate('Password2', 'Password2')"
        type="password"
        :error-messages="
          form.password === form.confirm_password
            ? null
            : 'Password does not match'
        "
      />
      <v-btn
        class="main primary mx-auto"
        type="submit"
        :disabled="
          errors.any() ||
            busy ||
            !form.confirm_password ||
            !form.password ||
            form.password !== form.confirm_password
        "
        :loading="busy"
      >
        submit
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { PURGE_AUTH_DATA, RESET_PASSWORD } from "@/store/users/auth.module";
import veeValidate from "@/mixins/veeValidate";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "reset-password-form",
  mixins: [veeValidate],
  props: {
    fromAuthCheck: {
      type: Boolean,
      default: false
    },
    resetToken: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {},
      busy: false
    };
  },
  computed: {
    ...mapGetters({
      getAuthMessage: "getAuthMessage"
    })
  },
  methods: {
    ...mapMutations({
      setAuthMessage: "setAuthMessage"
    }),
    async onSubmit() {
      this.setAuthMessage({ message: "", type: "" });
      if (this.errors.any() || this.busy) {
        return;
      }

      const password = this.form.password;
      const confirm_password = this.form.confirm_password;

      this.message = "";
      await this.$store.dispatch(PURGE_AUTH_DATA);
      this.busy = true;
      this.$store
        .dispatch(RESET_PASSWORD, {
          token: this.resetToken,
          password,
          confirm_password
        })
        .then(response => {
          if (this.fromAuthCheck) {
            this.$emit("success", {
              message: response.message,
              password: password
            });
          } else {
            this.$router.push({ name: "dashboard" }).catch(()=> {});
          }
          this.setAuthMessage({ message: response.message, type: "success" });
          this.$validator.errors.clear();
          this.busy = false;
          // this.resetForm();
        })
        .catch(err => {
          this.setAuthMessage({ message: err.message, type: "error" });
          this.$validator.errors.clear();
          this.busy = false;
        })
    }
  }
};
</script>

<style scoped></style>
