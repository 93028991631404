<template>
  <v-card class="pa-7">
    <div class="text-center">
      <div v-if="title" class="pb-6">
        <div class="font-bold font-size-h3">
          {{ title }}
        </div>
      </div>
      <div v-if="tab === 1" class="mb-4 font-size-h3">Let's start</div>
      <div v-if="tab === 2" class="mb-4 font-size-h3">
        <div class="text-left">
          <v-btn
            @click.stop="tab = 1"
            small
            text
            depressed
            color="primary"
            class="text-transform-none"
          >
            <v-icon v-text="'mdi-arrow-left'" left></v-icon>
            Back to Signin
          </v-btn>
        </div>
        <div>Create account</div>
      </div>
      <div v-if="tab === 3" class="mb-4 font-size-h3">Signin</div>
      <div v-if="tab === 5" class="font-size-h3">
        <div
          class="text-left cursor-pointer bodyFont primary--text"
          @click="tab = 3"
        >
          Back to Singin
        </div>
      </div>
    </div>
    <div>
      <div @keypress.enter="checkEmail" class="mt-7" v-if="tab === 1">
        <v-text-field
          v-model="email"
          filled
          autofocus
          placeholder="Enter your email address"
          v-validate="'required|email'"
          v-bind="veeValidate('Email', 'Email')"
        ></v-text-field>
        <div class="form-group d-flex flex-wrap justify-center align-center">
          <v-btn
            :loading="busy"
            :disabled="busy || errors.any()"
            @click="checkEmail"
            class="main primary"
          >
            Next
          </v-btn>
        </div>
        <social-auth
          button-text="Continue with"
          @loading="busy = $event"
          @success="afterSignIn"
        ></social-auth>
      </div>
      <!-- Signup -->
      <div v-if="tab === 2">
        <signup-form
          :email="form.email"
          @not-activated="gotoVerification"
          @activated="signinAgain"
          @sign-in="goToSignIn"
          @success="gotoVerification"
        ></signup-form>
      </div>
      <div v-if="tab === 5">
        <forgot-password
          from-auth-check
          :key="tab"
          :email="form.email || email"
          @success="afterPasswordChanged"
        ></forgot-password>
      </div>
      <!-- Signin -->
      <signin-form
        v-if="tab === 3"
        :email="form.email"
        @not-activated="gotoVerification"
        @forget-password="tab = 5"
        @change-email="tab = 1"
        @not-registered="goToSignUp"
        @success="afterSignIn"
      ></signin-form>
      <div class="" v-if="tab === 4">
        <verification-code-checker
          from-install
          :email="form.email"
          @change-email="changeEmail"
          @success="afterVerified"
        ></verification-code-checker>
      </div>
    </div>
    <vue-snack-bar></vue-snack-bar>
  </v-card>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { GET_STARTED_INFO } from "@/store/apps/createApp.module";
import { SIGNIN } from "@/store/users/auth.module";
import VerificationCodeChecker from "@/view/components/Common/VerificationCodeChecker";
import { GET_STARTED } from "@/store/team/invite.module";
import ForgotPassword from "@/view/components/User/ForgetPassword";
import SigninForm from "@/view/components/User/SigninForm";
import SignupForm from "@/view/components/User/SignupForm";
import { mapMutations } from "vuex";
import SocialAuth from "@/view/components/User/SocialAuth";
export default {
  components: {
    SignupForm,
    SigninForm,
    SocialAuth,
    ForgotPassword,
    VerificationCodeChecker,
  },
  mixins: [veeValidate],
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    inviteEmail: {
      type: String,
      default: null,
    },
    fromInvite: {
      type: Boolean,
      default: false,
    },
    fromInstall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: "",
      },
      email: "",
      tab: 1,
      busy: false,
    };
  },
  created() {
    this.setAuthMessage({});
    if (this.$route && this.$route.params.tab) {
      this.tab = parseInt(this.$route.params.tab || 1);
    }
    if (this.inviteEmail || this.$route.params.email) {
      this.form.email = this.$route.params.email || this.inviteEmail || "";
      this.email = this.$route.params.email || this.inviteEmail || "";
    }
  },
  methods: {
    ...mapMutations({
      setAuthMessage: "setAuthMessage",
    }),
    changeEmail() {
      this.tab = 2;
    },
    goToSignUp(email) {
      this.form.email = email;
      this.tab = 2;
    },
    updateUrl() {
      this.$router
        .push({
          name: this.$route.name,
          params: { tab: this.tab, email: this.email },
        })
        .catch(err => {
          this.error = err;
        });
    },
    async checkEmail() {
      if (await this.validateAllFields()) {
        if (this.fromInvite) {
          this.checkInviteEmail();
        } else {
          if (this.busy) return;
          this.busy = true;
          this.$store
            .dispatch(GET_STARTED_INFO, { email: this.email })
            .then(response => {
              this.form.email = this.email || "";
              this.tab = response.signup ? 2 : 3;
              this.updateUrl();
              this.busy = false;
            });
        }
      }
    },
    goToSignIn() {
      this.tab = 3;
      this.updateUrl();
    },
    checkInviteEmail() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(GET_STARTED, {
          id: this.$route.params.invite_id,
          email: this.email,
        })
        .then(response => {
          this.tab = response.signup ? 2 : 3;
          this.form.email = this.email || "";
          this.updateUrl();
          this.busy = false;
        })
        .catch(error => {
          this.busy = false;
          this.notifyErrorMessage(error.message);
        });
    },
    gotoVerification(email) {
      if (email) {
        this.form.email = email;
      }
      this.tab = 4;
    },
    afterPasswordChanged(details) {
      if (this.fromInvite) {
        this.signinAgain(details);
      } else {
        this.$emit("refresh");
      }
    },
    afterSignIn(message) {
      if (this.fromInvite) {
        this.goToStart(message);
      } else {
        this.$emit("refresh");
      }
    },
    async signinAgain(details) {
      await this.$store
        .dispatch(SIGNIN, {
          email: this.form.email || this.$route.query.email || "",
          password: details.password,
          invite_id: this.$route.params.invite_id || "",
        })
        .then(() => {
          if (this.fromInvite) {
            this.goToStart(details.message);
          } else if (this.fromInstall) {
            this.$emit("refresh");
          }
        })
        .catch(err => {
          this.tab = 3;
          this.notifyErrorMessage({
            message: err.message || "Invalid details",
          });
        });
    },
    goToStart(message) {
      this.$router
        .push({
          name: "start",
          params: {
            message:
              message ||
              "You have successfully accepted the invite. Welcome aboard :)",
          },
        })
        .catch(() => {});
    },
    afterVerified(message) {
      const newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: newUrl }, "", newUrl);
      if (this.fromInvite) {
        this.goToStart(message);
      }
      if (message) {
        this.setAuthMessage({ message: message, type: "success" });
      }
      this.$emit("refresh");
    },
  },
};
</script>

<style scoped></style>
