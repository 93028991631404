<template>
  <div>
    <v-row>
      <v-col v-if="getAuthMessage.message" cols="12">
        <vue-inline-alert
          :message="getAuthMessage.message"
          :message-type="getAuthMessage.type"
        ></vue-inline-alert>
      </v-col>
      <v-col class="py-0 text-center" cols="12">
        <div class="font-size-h3">Please check your email inbox</div>
        <div class="my-3 dark-grey-text bodyFont">
          We've emailed verification code to
          {{ verifyEmail }}
          <small @click="changeEmail" class="primary--text cursor-pointer"
            >(Change)</small
          >
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-row>
          <v-col class="py-0 mx-auto" cols="10" md="6" sm="12">
            <v-text-field
              v-model="verificationCode"
              :max="99999"
              autofocus
              placeholder="Code"
              prepend-inner-icon="mdi-email-outline"
              type="number"
              class="px-3 mx-auto font-weight-bold text-h5"
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-btn @click="verifyCode" :loading="busy" color="primary" outlined
          >Verify</v-btn
        >
      </v-col>
      <v-col class="text-center" cols="12">
        <div>
          Didn't get the email?
          <span
            v-if="!resendLoading"
            @click="resendEmail"
            class="primary--text cursor-pointer"
          >
            resend the email
          </span>
          <span v-else> ... </span>
        </div>
        <social-auth
          @loading="busy = $event"
          button-text="Sign up with"
          @success="$emit('success')"
          default-signin
        ></social-auth>
      </v-col>
      <v-slide-y-transition>
        <v-col v-if="showResendNote" class="mx-auto pl-2" cols="9">
          <div>
            <div>Note: If you didn't receive our verification email:</div>
            <ul>
              <li>Confirm that your e-mail address is correct.</li>
              <li>Check your spam or junk e-mail folder.</li>
            </ul>
          </div>
        </v-col>
      </v-slide-y-transition>
    </v-row>
  </div>
</template>

<script>
import {
  ACTIVATE_ACCOUNT,
  VERIFY_ACTIVATE,
  VERIFY_CODE,
  RESEND_TOKEN,
} from "@/store/users/auth.module";
import { mapGetters, mapMutations } from "vuex";
import SocialAuth from "@/view/components/User/SocialAuth";

export default {
  components: {
    SocialAuth,
  },
  props: {
    fromInstall: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: "",
    },
    action: {
      type: String,
      default: "verify_activate_token",
    },
    resetPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      verificationCode: "",
      verifyEmail: "",
      busy: false,
      errorMessage: "",
      resendLoading: false,
      showResendNote: false,
    };
  },
  computed: {
    ...mapGetters({
      getAuthMessage: "getAuthMessage",
      getRedirectedFromPlan: "getRedirectedFromPlan",
    }),
  },
  created() {
    this.verifyEmail = this.email || this.$route.query.email || "";
  },
  beforeDestroy() {
    this.setAuthMessage({ message: "", type: "" });
  },
  methods: {
    ...mapMutations({
      setAuthMessage: "setAuthMessage",
      setActivateErrorMessage: "setActivateErrorMessage",
    }),
    resendEmail() {
      if (this.resendLoading) return;
      this.resendLoading = true;
      this.setAuthMessage({ message: "", type: "" });
      let dataToPost = {
        action: this.resetPassword ? "reset_password" : "activate",
        invite_id: this.$route.params.invite_id || "",
        email: this.verifyEmail,
      };
      this.$store
        .dispatch(RESEND_TOKEN, dataToPost)
        .then((response) => {
          this.setAuthMessage({ message: response.message, type: "success" });
          this.showResendNote = true;
          this.resendLoading = false;
        })
        .catch((err) => {
          this.setAuthMessage({ message: err.message, type: "error" });
          this.resendLoading = false;
        });
    },
    changeEmail() {
      this.$emit("change-email", this.verifyEmail);
    },
    verifyCode() {
      this.setAuthMessage({ message: "", type: "" });
      if (this.busy) return;
      this.busy = true;
      this.$emit("error", null);
      this.errorMessage = null;
      if (this.verificationCode && this.verificationCode.length <= 5) {
        const dataToPost = {
          action: this.action,
          email: this.verifyEmail,
          code: parseInt(this.verificationCode),
        };
        this.$store
          .dispatch(VERIFY_CODE, dataToPost)
          .then((response) => {
            this.busy = false;
            this.$emit("next", response.token);
            if (!this.resetPassword) {
              this.activateUser(response.token);
            }
          })
          .catch((err) => {
            this.$emit("error", err.message);
            this.setAuthMessage({ message: err.message, type: "error" });
            this.errorMessage = err.message;
            this.busy = false;
          });
      } else {
        this.notifyErrorMessage("Please add 5 digit verification code");
        this.busy = false;
      }
    },
    setVerificationCode(value) {
      if (value) {
        this.verificationCode = value.substring(0, 5);
      }
    },
    activateUser(token) {
      this.setActivateErrorMessage(null);
      this.busy = true;
      this.$store
        .dispatch(VERIFY_ACTIVATE, token)
        .then(async ({ token }) => {
          await this.$store
            .dispatch(ACTIVATE_ACCOUNT, token)
            .then((response) => {
              if (this.fromInstall) {
                this.$emit(
                  "success",
                  response.message || "Your account successfully activated."
                );
              } else if (this.$route.name === "verify") {
                this.$router
                  .push({
                    name: "dashboard",
                  })
                  .catch(() => {});
              } else {
                this.$router
                  .push({
                    name: "start",
                    params: {
                      message:
                        response.message ||
                        "Your account successfully activated.",
                    },
                  })
                  .catch(() => {});
              }
              this.busy = false;
              if (this.getRedirectedFromPlan) {
                window.analytics.track("Redirected from landing", {
                  plan: this.getRedirectedFromPlan,
                });
              }
            })
            .catch((err) => {
              this.setAuthMessage({ message: err.message, type: "error" });
              this.busy = false;
            });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.setActivateErrorMessage(error.message);
          this.setAuthMessage({ message: error.message, type: "error" });
        });
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
